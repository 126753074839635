/** @jsx jsx */
import {
  useAnalytics,
  useCart,
  useTranslate,
  utils
} from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Fragment, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  jsx,
  Spinner
} from 'theme-ui'
import LineItems from '~/components/Cart/LineItems'
import CartSummary from '~/components/Cart/Summary'
import useUpdateLineItemShippingDates from '~/hooks/cogsy/use-update-line-item-shipping-dates'
import logSentryError from '~/utils/sentry'

const CartContainer = ({ children }) => {
  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['2rem', null, '4.5rem'],
          marginTop: ['2rem', null, '42px']
        }}
      >
        {children}
      </Flex>
    </Container>
  )
}

const CartHeading = () => {
  const translate = useTranslate()

  return (
    <div
      sx={{
        borderBottom: '1px solid #ddd',
        padding: '12px'
      }}
    >
      <Heading as="h1" variant="h1" sx={{ lineHeight: '1em' }}>
        {translate('cart.title')}
      </Heading>
    </div>
  )
}

const CartLoading = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
        backgroundColor: 'white',
        padding: ['10rem 1.25rem', '12rem 1.25rem']
      }}
    >
      <Spinner size="50" />
    </Box>
  )
}

const CartEmpty = () => {
  const translate = useTranslate()

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Heading
        as="h4"
        mb="1rem"
        mt="2rem"
        variant="body"
        sx={{
          variant: 'secondarySmall'
        }}
      >
        {translate('cart.empty')}
      </Heading>
      <Button
        as={Link}
        variant="primary"
        to="/collections"
        sx={{
          width: '100%',
          maxWidth: [null, null, '450px'],
          margin: [null, null, '0 auto']
        }}
      >
        {translate('cart.shop_button')} →
      </Button>
    </Box>
  )
}

const CartLineItems = ({
  items,
  amountNeededForFreeShipping,
  eligibleForFreeShipping
}) => (
  <Fragment>
    <CartHeading />
    <LineItems
      items={items}
      amountNeededForFreeShipping={amountNeededForFreeShipping}
      eligibleForFreeShipping={eligibleForFreeShipping}
      source={'cart'}
    />
  </Fragment>
)

const CartPage = () => {
  const { cart, loadCart, isFetching } = useCart()
  const { trackCartViewed } = useAnalytics()
  const { getAllCartPromotionsForDisplay } = utils
  const updateLineItemShippingDates = useUpdateLineItemShippingDates()

  useEffect(() => {
    updateLineItemShippingDates()
  }, [updateLineItemShippingDates])

  useEffect(() => {
    const loadAndTrackCart = async () => {
      try {
        await loadCart()
        trackCartViewed()
      } catch (error) {
        logSentryError(error, { source: 'Cart' })
      }
    }

    loadAndTrackCart()
  }, [loadCart, trackCartViewed])

  const {
    displayTotal,
    displayItemTotal,
    displayTaxTotal,
    displayShipTotal,
    lineItems,
    total
  } = cart
  const freeShippingThreshold = process.env.GATSBY_FREE_SHIPPING_THRESHOLD || 0
  const amountNeededForFreeShipping = Math.max(0, freeShippingThreshold - total)

  const eligibleForFreeShipping = amountNeededForFreeShipping === 0

  if (isFetching && cart.lineItems?.length === 0) return <CartLoading />
  const cartIsEmpty = !cart.lineItems || cart.lineItems.length === 0

  const formatCurrency = value => `$${(+value).toFixed(2)}`

  const giftCardsAmountUsed = cart.appliedGiftCards?.reduce(
    (acc, cur) => acc + +cur.amountUsedV2.amount,
    0
  )

  const promotions = getAllCartPromotionsForDisplay(cart)

  return (
    <CartContainer>
      <Grid columns={[1, null, 2]} gap={['2rem', null, '6rem']}>
        <div sx={{ borderTop: '1px solid #ddd' }}>
          {cartIsEmpty ? (
            <CartEmpty />
          ) : (
            <CartLineItems
              items={lineItems}
              amountNeededForFreeShipping={amountNeededForFreeShipping}
              eligibleForFreeShipping={eligibleForFreeShipping}
            />
          )}
        </div>
        <div>
          <CartSummary
            displayItemTotal={displayItemTotal}
            displayTaxTotal={displayTaxTotal}
            displayShipTotal={displayShipTotal}
            displayTotal={displayTotal}
            promotions={promotions}
            eligibleForFreeShipping={eligibleForFreeShipping}
          />
        </div>
      </Grid>
    </CartContainer>
  )
}

export default CartPage

CartContainer.propTypes = {
  children: PropTypes.any
}

CartLineItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  amountNeededForFreeShipping: PropTypes.number,
  eligibleForFreeShipping: PropTypes.bool
}
