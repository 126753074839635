/** @jsx jsx */
import PropTypes from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Flex, jsx, Spinner, Text } from "theme-ui";
import FormInputWithLabel from "~/components/Generic/Form/InputWithLabel";
import { useCart, useTranslate } from "@chordcommerce/gatsby-theme-autonomy";

const PromoCode = ({ promotion }) => {
  const translate = useTranslate();
  const { addPromoCode, removePromoCode, isFetching } = useCart();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      promoCode: promotion ? promotion.code : "",
    },
  });
  const [apiError, setApiError] = useState(null);
  const [isFormActive, setIsFormActive] = useState(promotion ? true : false);

  if (!promotion) {
    return null;
  }

  const onSubmit = async (data, e) => {
    if (promotion) {
      try {
        setApiError(null);
        await removePromoCode(promotion.code);
        e.target.reset();
      } catch (error) {
        setApiError(error);
      }
    } else {
      try {
        setApiError(null);
        await addPromoCode({ promoCode: data.promoCode });
      } catch (error) {
        setApiError(error);
      }
    }
  };

  const activatePromoCodeForm = (event) => {
    if (event) event.preventDefault();
    setIsFormActive(true);
  };

  if (!isFormActive) {
    return (
      <Flex sx={{ flexDirection: "column", my: "1rem" }}>
        <Box as="form" onSubmit={(e) => activatePromoCodeForm(e)}>
          <Button sx={{ width: "100%" }}>
            {translate("cart.apply_promo_code")}
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex sx={{ flexDirection: "column", my: "1rem" }}>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Flex
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "80%",
              marginRight: "1rem",
            }}
          >
            <FormInputWithLabel
              label={translate("cart.promo_code")}
              title={translate("cart.promo_code")}
              aria-label={translate("cart.promo_code")}
              placeholder={translate("cart.promo_code")}
              name="promoCode"
              disabled={promotion}
              ref={register({ required: true })}
              sx={{ color: "#233437" }}
            />
          </Box>
          <Button>
            {isFetching && <Spinner size="15" color="inherit" />}
            {!isFetching &&
              (promotion
                ? translate("cart.remove_promo_code_button")
                : translate("cart.apply_promo_code_button"))}
          </Button>
        </Flex>
      </Box>
      {apiError && (
        <Text
          variant="formError"
          sx={{
            color: "errorDark",
            backgroundColor: "errorLight",
            px: "16px",
            py: "8px",
          }}
        >
          {apiError.message}
        </Text>
      )}
      {errors.promoCode && (
        <Text
          variant="formError"
          sx={{
            color: "errorDark",
            backgroundColor: "errorLight",
            px: "16px",
            py: "8px",
          }}
        >
          {translate("validation.required_field")}
        </Text>
      )}
    </Flex>
  );
};

PromoCode.propTypes = {
  promotion: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    code: PropTypes.string,
    amount: PropTypes.number,
    displayAmount: PropTypes.string,
  }),
};

export default PromoCode;
