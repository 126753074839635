import React from 'react'
import CartPage from '~/components/Cart/Page'
import { Metadata, Page } from '~/components/Layout'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const Cart = () => {
  const translate = useTranslate()

  return (
    <Page>
      <Metadata
        title={translate('cart.page_title')}
        description="We design non-toxic ceramic cookware and bakeware that has raised the standards of what you cook and bake with. Free Shipping* $90+, Free Returns, &amp; 30-Day Trial"
      />
      <CartPage />
    </Page>
  )
}

export default Cart
